<template>
  <div class="reportes-page" style="background-color: #F6F8F9;">
    <div class="container py-5">
      <h2 class="title">Reportes</h2>
      <p>Página en construcción.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reportes',
};
</script>

<style scoped>
.title {
  color: #193238;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}
</style>
